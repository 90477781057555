body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  font-family: Times New Roman;
}

.background {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-image: url(../image/background.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.app {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  font-family: Times New Roman;
}

.Hash {
  word-wrap: break-word; /* 允许在长单词内部换行 */
  word-break: break-all; /* 允许在任意位置换行 */
}

.single-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.align-items {
  align-items: center;
}
.justify-content {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.pointer {
  cursor: pointer;
}

.input-area input[type="text"] {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.input-area button {
  margin-left: 10px;
  padding: 10px 20px;
  /* background-color: #4CAF50; */
  color: #000;
  font-weight: bold;
  border: none;
  cursor: pointer;
}


.marketplace-link {
  display: block;
  margin-top: 20px;
  padding: 10px;
  background-color: #2196F3;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.agent-tag {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}


.mentioned-agent {
  font-weight: bold;
  color: #4CAF50;
}

.selected-agent {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.selected-agent p {
  margin: 0;
  font-weight: bold;
}

.marketplace-button, .use-agent-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.marketplace-button:hover, .use-agent-btn:hover {
  background-color: #45a049;
}

.agent-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
}

.select-btn, .view-details-btn {
  width: 100px;
  height: 30px;
  padding: 8px 10px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.training-history-item {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.training-history-item p {
  margin: 5px 0;
}

.training-history-item .timestamp {
  font-style: italic;
  color: #666;
}

.training-history-item .ipfs-hash {
  word-break: break-all;
}

.training-history-item .ipfs-hash a {
  color: #0066cc;
  text-decoration: none;
}

.training-history-item .ipfs-hash a:hover {
  text-decoration: underline;
}

.marketplace {
  padding: 20px;
  overflow-y: auto;
  width: 870px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 10px;
}

.agent-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.agent-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
  font-size: 14px;
}

.marketplace-agent {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.agent-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.use-agent-btn, .select-btn, .view-details-btn, .marketplace-button, .cancel-selection-btn {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: Times New Roman;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.view-details-btn {
  background: #C2EB47;
  border-radius: 19px;
  font-size: 14px;
  color: #022222;
  margin-top: 10px;
}

.cancel-selection-btn {
  background-color: #f44336;
}

.use-agent-btn:hover, .select-btn:hover, .view-details-btn:hover, .marketplace-button:hover, .cancel-selection-btn:hover {
  opacity: 0.8;
}

.selected-agent {
  background-color: #e6f7ff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.task-decomposition h2 {
  color: #333;
  margin-bottom: 20px;
}

.task-decomposition h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.task-decomposition textarea {
  width: 100%;
  min-height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.task-decomposition button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.task-decomposition button:hover {
  background-color: #45a049;
}

.task-decomposition {
  max-width: 84%;
  margin: 10px 0;
  padding: 15px;
  background-color: #F3F3F3;
  border-radius: 18px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.subtask-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subtask-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 12px;
}

.subtask-item h4 {
  font-size: 16px;
  margin-bottom: 8px;
  color: #222;
}

.subtask-item p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #444;
}

.input-area input:disabled,
.input-area button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.message {
  max-width: 80%;
  padding: 15px;
  border-radius: 18px;
  line-height: 1.5;
  word-wrap: break-word;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message.user {
  align-self: flex-end;
  background-color: #DCF8C6;
  border-bottom-right-radius: 4px;
}

.message.system, .message.agent {
  align-self: flex-start;
  background-color: #F3F3F3;
  border-bottom-left-radius: 4px;
  font-size: 14px;
  font-family: Times New Roman;
}


/* Markdown 样式 */
.message p {
  margin: 0 0 10px 0;
}

.message p:last-child {
  margin-bottom: 0;
}

.message h1, .message h2, .message h3, .message h4, .message h5, .message h6 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.message ul, .message ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

.message code {
  font-size: 85%;
  background-color: rgba(27,31,35,0.05);
  border-radius: 3px;
  padding: 0.2em 0.4em;
}

.message pre {
  background-color: #f6f8fa;
  border-radius: 6px;
  padding: 16px;
  overflow-x: auto;
  font-size: 14px;
  line-height: 1.45;
  margin: 10px 0;
}


.message pre code {
  background-color: transparent;
  padding: 0;
}

.message blockquote {
  border-left: 4px solid #ccc;
  margin-left: 0;
  padding-left: 10px;
  color: #666;
}

.message img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
}

.message table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}

.message th, .message td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.message th {
  background-color: #f4f4f4;
}


.code-highlight pre {
  background-color: #f6f8fa !important;
  padding: 16px !important;
  overflow: auto !important;
  font-size: 85% !important;
  line-height: 1.45 !important;
  border-radius: 3px !important;
}

.code-highlight code {
  font-family: Times New Roman;
  font-size: 1em !important;
}

.solidity-highlight pre {
  background-color: #f6f8fa !important;
  padding: 16px !important;
  overflow: auto !important;
  font-size: 85% !important;
  line-height: 1.45 !important;
  border-radius: 3px !important;
}

.solidity-highlight code {
  font-family: Times New Roman;
  font-size: 1em !important;
}

.markdown-content pre {
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 16px;
  overflow-x: auto;
}

.markdown-content code {
  font-family: Times New Roman;
  font-size: 85%;
}

.code-block-wrapper {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
}

.copy-button:hover {
  background-color: #45a049;
}

.code-block-wrapper {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
}

.copy-button:hover {
  background-color: #45a049;
}



.back-button {
  display: inline-block;
  margin-bottom: 20px;
  color: #333;
  text-decoration: none;
  background: #EBEDE6;
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 13px;
  width: 110px;
}

.edit-form input,
.edit-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}



 .save-button {
  background-color: #4CAF50;
  color: white;
}


.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.training-button {
  margin-top: 20px;
  background-color: #ff9800;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.team-actions .Delete:hover {
  background-color: #d32f2f;
}

.message::-webkit-scrollbar {
  width: 6px;
}

.message::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.message::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.message::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.code-block {
  position: relative;
  margin: 10px 0;
}

.download-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 8px;
  text-decoration: none;
}

.download-btn:hover {
  background-color: #45a049;
}

.message {
  max-width: 80%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.message.user {
  align-self: flex-end;
  background-color: #e3f2fd;
}

.message.agent, .message.system {
  align-self: flex-start;
  background-color: #f1f8e9;
}

.message.agent {
  position: relative;
}

.markdown-content {
  line-height: 1.6;
}

.markdown-content p {
  margin-bottom: 10px;
}

.markdown-content pre {
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 16px;
  overflow-x: auto;
}

.markdown-content code {
  font-family: Times New Roman;
  font-size: 85%;
}

.contract-deployment-sidebar {
  width: 300px;
  padding: 20px;
  border-left: 1px solid #e8e8e8;
  overflow-y: auto;
}

.contract-deployment {
  display: flex;
  flex-direction: column;
}

.contract-deployment h3 {
  margin-bottom: 15px;
}

.contract-deployment .ant-form-item {
  margin-bottom: 10px;
}

.evm-theme {
  --primary-color: #3498db;
  --secondary-color: #2980b9;
}

.solana-theme {
  --primary-color: #9945FF;
  --secondary-color: #14F195;
}

.contract-deployment {
  padding: 20px;
  border-radius: 8px;
}

.contract-deployment.evm-theme {
  background-color: rgba(52, 152, 219, 0.1);
}

.contract-deployment.solana-theme {
  background-color: rgba(153, 69, 255, 0.1);
}

.network-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.ant-btn {
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
}

.ant-btn:hover, .ant-btn:focus {
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
}

.ant-modal {
  color: rgba(0, 0, 0, 0.85);
}

.ant-modal-content {
  color: rgba(0, 0, 0, 0.85);
}

.ant-modal-header {
  color: rgba(0, 0, 0, 0.85);
}

.ant-btn {
  color: rgba(0, 0, 0, 0.85);
}

.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.ant-btn-primary {
  color: #fff;
}

.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.ant-alert {
  color: rgba(0, 0, 0, 0.85);
}

.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}

.ant-alert-description {
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-selection-item {
  color: rgba(0, 0, 0, 0.85);
}

.contract-deployment-modal {
  .ant-modal-content {
    background: #fff;
    padding: 24px;
  }

  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-btn {
    height: 32px;
    padding: 4px 15px;
    border-radius: 2px;
  }

  .ant-btn-primary {
    background: #1890ff;
    border-color: #1890ff;
    color: #fff;

    &:hover {
      background: #95cfff;
      border-color: #95cfff;
    }

    &[disabled] {
      background: #f5f5f5;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-alert {
    margin-bottom: 24px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.form-hint {
  display: block;
  margin-top: 5px;
  color: #666;
  font-size: 0.9em;
  font-style: italic;
}

.agents-container {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.selected-agents-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.selected-agent-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 5px 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76,175,80,0.1);
}

.create-team-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.buyTeam {
  position: absolute;
  width: 132px;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 5px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  top: 227px;
}

.buyTeam div {
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    background: #95cfff;
    border-color: #95cfff;
  }
}

.input-area {
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  position: relative;
}
.agent-avatar {
  margin-right: 10px;
}

.agent-avatar img {
  width: 40px;
  height: 40px;
}

.GameMode {
  width: 40px;
  height: 40px;
}
.game-mode-toggle {
  padding: 0;
  background: none;
  margin-left: 0;
  margin-right: 10px;
}
.preview-card {
  margin-bottom: 20px;
}

.Claim-Button {
  background-color: #0066cc;
}
.Claim-Alert {
  margin-top: 15px;
}
